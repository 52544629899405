<style>
  .list-extras{
    list-style: none;
  }
  .label-check{
    display: flex;
    align-items: center;
  }
</style>
<template>
  <div>
    <Loading :active="loading" :fullPage="true" />
    <portal to="title-page">
      <h1 v-if="landing">{{landing.name}}</h1>
    </portal>
    <landing-tabs v-if="landing" tab="extras" :landing="landing">
      <v-card>
        <v-card-text>
          <h3>Extras</h3>
          <p>Seleccione los extras que se mostraran en la landing page</p>
          <ul class="list-extras">
            <li v-for="(e, key) in extras" :key="key" class="d-flex">
              <v-checkbox :id="`check${key}`" v-model="selectedExtras" :value="e.id"></v-checkbox>
              <label :for="`check${key}`" class="label-check">
                {{ e.name }}
              </label>
            </li>
          </ul>
          <v-btn
            color="primary"
            class="mr-4"
            @click="save"
          >
            <v-icon>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-text>
      </v-card>
    </landing-tabs>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Landing from "@/models/Landing.js";
  import LandingTabs from "@/components/admin/LandingTabs";
  import ExtraModel from "@/models/Extra";
  // import firebase from 'firebase/app';
  // import 'firebase/firestore';
  export default {
    name: "AdminCMSLandingEditExtras",
    components: { LandingTabs },
    data() {
      return {
        landingModel:new Landing,
        settings: [],
        extraModel:new ExtraModel,
        ///////////////////////////////////////////////
        landing:null,
        extras: null,
        loading: false,
        selectedExtras: [],
        snackbar: {
          show: false,
          color: '',
          message: ''
        },
        //fecha: firebase.firestore.Timestamp.fromDate(new Date),
      };
    },
    methods: {
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      save() {
        this.loading = true
        this.landingModel.update(this.landingId, {
          extras: this.selectedExtras
        }).then(() => {
          this.showSnackbarSuccess('Los extras se guardaron correctamente')
          this.loading = false
        })
        .catch(() => {
          this.showSnackbarError('Error al guardar los extras')
          this.loading = false
        })
      }
    },
    mounted() {
      this.landingId = this.$route.params.id;
      this.landingModel.findByLandingId(this.landingId).onSnapshot((doc) => {
        this.landing = doc.data();
      });
      this.extras = []
      this.extraModel.getActives().onSnapshot((snap) => {
        snap.forEach((doc) => {
          console.log('doc.data().panels', doc.data().panels);
          const add = doc.data().panels.website || false
          if (add) {
            this.extras.push({
              ... doc.data()
            })
            if (!(this.landing.extras.indexOf(doc.id) < 0)) {
              this.selectedExtras.push(doc.id)
            }
          }
        })
      });
    },
    created() {
      this.$store.commit("SET_LAYOUT", "admin");
    },
  };
</script>
<style>
  .file-input-width{
    flex-basis: calc(100% - 100px);
  }
</style>